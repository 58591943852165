import { defineStore } from 'pinia'
import { ofetch } from 'ofetch'
import type { PayloadVal, TabData } from '~/models/Search'
import type { JobItem } from '~/models/Job'
import type { CompanyItem } from '~/models/Company'
import { useJobs } from '~/store/job'

interface SearchState {
  tabs: TabData[]
  countInit: number
  isLoading: boolean
  companyList: { version1: CompanyItem[]; version2: CompanyItem[] }
  jobList: JobItem[]
  searchType: string
}

export const useSearch = defineStore('search', {
  state: (): SearchState => ({
    tabs: [
      { name: 'jobs', isActive: true, size: 21, total: 0, query: { st: 'jobs', q: '', locations: null, employment: null, work_type: null, date_posted: null, sort: 'date', page: 1 } },
      { name: 'companies', isActive: false, size: 21, total: 0, query: { st: 'companies', q: '', location: null, industry: null, page: 1 } },
    ] as TabData[],
    countInit: 0,
    isLoading: false,
    companyList: { version1: [], version2: [] },
    jobList: [],
    searchType: null,
  }),

  actions: {
    resetSearchTabs() {
      this.tabs = [
        { name: 'jobs', isActive: true, size: 21, total: 0, query: { st: 'jobs', q: '', locations: null, employment: null, work_type: null, date_posted: null, sort: 'date', page: 1 } },
        { name: 'companies', isActive: false, size: 21, total: 0, query: { st: 'companies', q: '', location: null, industry: null, page: 1 } },
      ]
    },
    resetSearchResult() {
      this.companyList = { version1: [], version2: [] }
      this.jobList = []
    },
    getDateRange(option: string) {
      const now = new Date()
      let days

      switch (option) {
        case '24hours': days = 1; break
        case '7days': days = 7; break
        case '2weeks': days = 14; break
        case '1month': days = 30; break
        default: days = 0
      }

      const date_published_from = new Date(now.getTime() - days * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
      const date_published_to = now.toISOString().split('T')[0]

      return { date_published_from, date_published_to }
    },
    async setJobList(payloadVal: PayloadVal) {
      const route = useRoute()
      const config = useRuntimeConfig()
      // check if current url have st === jobs OR if the search param not empty, because if not empty meaning its a longtail url
      const isActive = route.query?.st === 'jobs' || !isEmpty(route.params?.search)

      // only set loading true in client for UX
      if (process.client)
        this.isLoading = true
      await Promise.all([
        useAsyncData('init_search_job', () =>
          ofetch(`${config.public.SEARCHAPI_BASE_URL}/jobs/search`, {
            method: 'POST',
            headers: {
              'x-api-key': config.SEARCHAPI_KEY,
            },
            body: {
              country: useGetSubdomain(),
              cities: (isEmpty(payloadVal.locations) || payloadVal.locations[0] === 'all') ? '' : useTextToParam(payloadVal.locations, true).split(','),
              types: isEmpty(payloadVal.type) ? ['full_time', 'part_time', 'freelance', 'internship'] : payloadVal.type,
              remote: payloadVal.remote,
              date_published_from: payloadVal.date ? this.getDateRange(payloadVal.date).date_published_from : null,
              date_published_to: payloadVal.date ? this.getDateRange(payloadVal.date).date_published_to : null,
              term: payloadVal.keyword,
              from: (payloadVal.from - 1) * payloadVal.size,
              size: payloadVal.size,
              sort: payloadVal.sort,
              type: 'sematic_search_with_similar_titles_search',
            },
          }),
        ),
      ]).then(async (values) => {
        const resp = values[0]?.data?.value as { total: number; hits?: JobItem[] }
        if (!isEmpty(resp)) {
          this.jobList = resp.hits
          this.tabs[0] = {
            name: 'jobs',
            isActive,
            total: resp?.total | 0,
            size: payloadVal.size,
            query: {
              st: 'jobs',
              q: payloadVal.keyword,
              locations: payloadVal.locations.length > 0 ? payloadVal.locations.join(',') : 'all',
              employment: payloadVal.type.length > 0 ? payloadVal.type.join(',') : 'all',
              date_posted: !isEmpty(payloadVal.date) ? payloadVal.date : 'all',
              work_type: !isEmpty(payloadVal.remote) ? payloadVal.remote : 'all',
              sort: payloadVal.sort,
              page: payloadVal.from,
            },
          }
        }

        if (isActive) {
          // meaning user only change filter on job tab, or click on the job card internal link
          this.tabs[1].isActive = false
          this.tabs[0].isActive = true
          this.isLoading = false
        }
        else {
          // meaning user search by searchbar, empty state option
          this.countInit++
        }
      })
    },
    async setCompanyList(payloadVal: PayloadVal) {
      const route = useRoute()
      const gicsList = useGetGICS()
      const config = useRuntimeConfig()
      // check if current url have st === companies
      const isActive = route.query?.st === 'companies'

      // only set loading true in client for UX
      if (process.client)
        this.isLoading = true

      await Promise.all([
        useAsyncData('init_search_company', () =>
          ofetch(`${config.public.SEARCHAPI_BASE_URL}/v2/companies/search`, {
            method: 'POST',
            body: {
              country: useGetSubdomain(),
              city: payloadVal.location,
              industry: !isEmpty(payloadVal.industry) ? gicsList.find(obj => obj.value === payloadVal.industry)?.name.replaceAll('and', '&') : '',
              term: payloadVal.keyword,
              order: 'date',
              from: (payloadVal.from - 1) * payloadVal.size,
              size: payloadVal.size,
            },
          }),
        ),
      ]).then(async (values) => {
        const resp = values[0]?.data?.value as { total: number; hits?: CompanyItem[] }
        if (!isEmpty(resp)) {
          this.companyList = !isEmpty(resp?.hits) ? { version1: resp?.hits.filter(company => company.employerVersion !== 2), version2: resp?.hits.filter(company => company.employerVersion === 2) } : { version1: [], version2: [] }
          this.tabs[1] = {
            name: 'companies',
            isActive,
            total: resp?.total | 0,
            size: payloadVal.size,
            query: {
              st: 'companies',
              q: payloadVal.keyword,
              location: !isEmpty(payloadVal.location) ? payloadVal.location : 'all',
              industry: !isEmpty(payloadVal.industry) ? payloadVal.industry : 'all',
              page: payloadVal.from,
            },
          }
        }

        if (isActive) {
          // meaning user only change filter on job tab, or click on the job card internal link
          this.tabs[0].isActive = false
          this.tabs[1].isActive = true
          this.isLoading = false
        }
        else {
          // meaning user search by searchbar, empty state option
          this.countInit++
        }
      })
    },
    async setActive(payloadVal: PayloadVal) {
      const route = useRoute()
      const isCompanyActive = route.query?.st === 'companies'

      // only set loading true in client for UX
      if (process.client)
        this.isLoading = true
      this.countInit = 0

      await this.setJobList(payloadVal)
      await this.setCompanyList(payloadVal)

      if (this.countInit === 2) {
        // if user come from other page than search, automatically set tab job as active
        this.tabs[0].isActive = isEmpty(route.query?.st) ? true : isCompanyActive === false
        this.tabs[1].isActive = isEmpty(route.query?.st) ? false : isCompanyActive === true
      }

      this.countInit = 0
      this.isLoading = false
    },
  },

  persist: {
    key: 'search',
    storage: process.client ? window.localStorage : null,
  },
})
