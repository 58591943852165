<script lang="ts" setup>
import { Dialog, DialogPanel, DialogTitle, Listbox, ListboxButton, ListboxOption, ListboxOptions, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { Float } from '@headlessui-float/vue'
import locales from '~/assets/data/locales.json'

interface Props {
  redirectTo: string | undefined
}

const props = defineProps<Props>()
const emit = defineEmits(['update:langRedirect'])
const config = useRuntimeConfig()
const route = useRoute()
const localeList = ref(locales)
const selectedLang = ref(!isEmpty(useGetSubdomain()) ? useGetSubdomain() : null)
const region = useCookie('region', { path: '/', domain: config.public.COOKIE_DOMAIN })
const isLangSelected = useCookie('isLangSelected', { path: '/', domain: config.public.COOKIE_DOMAIN })
const isLangModalOpen = useCookie('isLangModalOpen', { path: '/', domain: config.public.COOKIE_DOMAIN })
const isOpen = ref()

onMounted(() => {
  isOpen.value = (isLangModalOpen.value || isLangModalOpen.value === 'true') || (isEmpty(useGetSubdomain()))
})

watch(() => props.redirectTo, (newValue) => {
  if (!isEmpty(newValue))
    isOpen.value = true
})

watch(() => isLangModalOpen.value, (newValue) => {
  isOpen.value = newValue === 'true'
})

function onLangSelect() {
  isLangSelected.value = 'true'
  region.value = selectedLang.value

  if (!isEmpty(props.redirectTo)) {
    // this is for case : user access career-guide from root
    window.location.href = new URL(route.fullPath, useSetSubdomain(selectedLang.value)).href.slice(0, -1) + props.redirectTo
  }
  else {
    if (route.path.includes('/career-guide')) {
      // added this so when user change region when accessing article detail, it will redirect them to /career-guide instead of /career-guide/sss
      window.location.href = new URL('/career-guide', useSetSubdomain(selectedLang.value)).href
    }
    else {
    // check if user on search page, if yes then redirect to requested region + reset location query
    // if user not on search page, just redirect to that page without changing any query
      const modifiedUrl = new URL(route.fullPath, useSetSubdomain(selectedLang.value))
      if (route.path === '/search' && !isEmpty(route.query))
        modifiedUrl.searchParams.set('location', 'all')
      window.location.href = modifiedUrl.href
    }
  }
  isLangModalOpen.value = 'false'
}

function onCloseModal() {
  // if user on first session didnt choose any lang, system automatically set us as the default value
  isLangSelected.value = 'true'
  region.value = 'us'
  isLangModalOpen.value = 'false'
  emit('update:langRedirect', null)
}
</script>

<template>
  <div>
    <ClientOnly>
      <TransitionRoot appear :show="isOpen" as="template">
        <Dialog as="div" class="relative z-30">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-black bg-opacity-25" style="backdrop-filter: blur(10px)" />
          </TransitionChild>

          <div class="fixed inset-0 overflow-y-auto">
            <div class="flex min-h-full items-center justify-center">
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <DialogPanel class="w-full max-w-sm md:max-w-md min-h-xs transform overflow-hidden rounded-lg bg-white p-6 shadow-xl transition-all text-sm">
                  <DialogTitle class="text-xl font-bold leading-6 text-gray-900 flex mb-6">
                    Personalized jobs and companies
                    <Icon name="heroicons:x-mark" class="w-5 h-5 ml-auto mt-1 cursor-pointer" @click="onCloseModal()" />
                  </DialogTitle>
                  <div class="font-semibold mb-2">
                    Choose Region
                  </div>
                  <Listbox v-slot="{ open }" class="w-full" @update:model-value="(item: string) => selectedLang = item">
                    <Float portal :offset="5" as="div" class="relatives" floating-as="template" :z-index="0">
                      <ListboxButton :class="`${open ? 'border-primary-400' : ''} relative pl-4 pr-10 py-2.5 bg-white rounded-md text-sm w-full text-left border border-gray-200`">
                        <div class="block truncate flex items-center">
                          <Icon :name="localeList.find(obj => obj.region === selectedLang)?.icon || 'material-symbols:language'" class="w-5 h-5 mr-2" />
                          {{ localeList.find(obj => obj.region === selectedLang)?.label || 'Choose Region' }}
                        </div>
                        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <Icon v-if="!open" name="mdi:chevron-down" class="h-4 w-4" aria-hidden="true" />
                          <Icon v-else name="mdi:chevron-up" class="h-4 w-4" aria-hidden="true" />
                        </span>
                      </ListboxButton>
                      <div class="w-full max-w-[336px] md:max-w-md md:pr-12">
                        <ListboxOptions class="rounded-md shadow-lg bg-white w-full focus:outline-none">
                          <ListboxOption v-for="(item, idx) in localeList" v-slot="{ active }" :key="idx" :value="item.region" as="template">
                            <li :class="`cursor-pointer select-none px-4 py-2.5 focus:outline-none text-sm hover:bg-primary-50 ${(active || useGetSubdomain() === item.region) ? 'bg-primary-50' : ''}`">
                              <Icon :name="item.icon" class="w-5 h-5 mr-2" />
                              {{ item.label }}
                            </li>
                          </ListboxOption>
                        </ListboxOptions>
                      </div>
                    </Float>
                  </Listbox>
                  <div class="text-sm p-2.5 rounded-md bg-[#ece9fc] border border-[#7865eb] mt-6 flex items-center">
                    <Icon name="ic:baseline-info" class="w-5 h-5 mr-2 text-[#5843e5]" />
                    You can always change it later
                  </div>
                  <BaseAtomsButton size="sm" class="text-sm font-bold w-full mt-18" @click="onLangSelect()">
                    Continue
                  </BaseAtomsButton>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
    </ClientOnly>
  </div>
</template>
